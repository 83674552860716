<template>
  <div class="main-container">
    <div class="stoploss-wrapper">
      <h3 id="title-text" class="typo-headline-6">Calcular stoploss</h3>

      <p id="text-description" class="typo-body-2 stoploss-text">Herramienta de inversión para calcular el Stop Loss de los valores. Utilice nuestra calculadora y conoce el lugar correcto del Stop Loss según la metodología de Eurekers.</p>
        
      <div class="center-container">
        <InputTextOutline @_emit_method_order_input="_getValueFromComponentStoploss" :input_label="input_stoploss.input_label" :input_helper="input_stoploss.input_helper" :has_focus="input_stoploss.has_focus" :icon_class="input_stoploss.icon_class" :input_key_to_save_user_values="input_stoploss.input_key_to_save_user_values" :input_class_character_counter="input_stoploss.input_class_character_counter" :permission="input_stoploss.permission" :icon_permission="input_stoploss.icon_permission" :character_counter_permission="input_stoploss.character_counter_permission" :helper_permission="input_stoploss.helper_permission" :test_field_is_numeric="input_stoploss.test_field_is_numeric" :length="input_stoploss.length" :is_input_textarea="input_stoploss.is_input_textarea" class="stoploss-input"/> 
          
        <div class="stoploss-ipo">
          <p id="toogle-text-left" class="text-grey margin-right">2%</p>
          <Toggle :component_name="switch_name" @_emit_method_check_toggle="_getValueFromSwitch"  />
          <p id="toogle-text-right" class="text-grey margin-left">3%</p>
        </div>

        <hr>

        <div class="stoploss-result">
          <p id="result-text" class="text-grey">RESULTADO <span class="text-black">{{ getResult }} </span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import PortfolioCalculator from '@/views/PortfolioCreatorCalculator.vue';
import Toggle from '@/components/helpers/Toggle.vue';
import InputTextOutline from '@/components/helpers/InputTextOutline.vue';
import inputStoploss from './configs/stoploss/stoploss.json';

export default {
  name: 'StopLoss',

  metaInfo() {
    return {
      title: 'Calculadora de stoploss Eurekers',
      meta:[
        { name: 'description', content: "La calculadora de stoploss permite calcular el stoploss de los valores, ofrecida por Eurekers"}
      ]
    }
  },

  components: {
    InputTextOutline,
    Toggle,
    //PortfolioCalculator
  },
  data() {
    return {
      calculator_id: "PortfolioCreatorCalculator",
      input_stoploss : inputStoploss,

      emit_method: '_get_stoploss_input_value',
      emit_switch: '_get_switch_value',
      emit_switch_one: '_get_switch_one_value',

      isIpoCheckbox: false,
      
      price: '0',
      switch_name: 'stop_loss_is_ipo',
      
      stoploss_result: '',
      input_stoploss_value: '',
    };
  },
  computed: {
    getResult: function(){
      this._calculateStopPrice();

      return (this.input_stoploss_value == '' || this.input_stoploss_value == 0 || isNaN(this.input_stoploss_value)) ? '' : this.stoploss_result + ' €';
    },
  },
  methods: {
    _calculateStopPrice() {
      let is_ipo = this.isIpoCheckbox;
      let price = parseInt(this.input_stoploss_value);
      let filtro = (is_ipo == true) ? 0.03 : 0.02;
      let result = (price * (1 - filtro)).toFixed(2);
      let r = result.toString();
      let last = r.substr(-1);

      if  (last == "5" || last == "0") {
        let a = (result - 0.01).toFixed(2)
        this.stoploss_result = a;
      } else {
        this.stoploss_result = result;
      }
    },
    _getValueFromComponentStoploss(value) {
      this.input_stoploss_value = value[0];
    },
    _getValueFromSwitch(value) {
      this.isIpoCheckbox = value[0];
    },
  }
}
</script>




<style lang="scss" scoped>
  @use "@material/switch";
  @include switch.core-styles;

  .mdc-switch {
    @include switch.toggled-on-color(rgba(0, 0, 0, 0.6));
    @include switch.toggled-off-color(rgba(0, 0, 0, 0.2));
  }

  $color_black: var(--color-text-primary);
  $color_dark: #000010;
  $color_grey: var(--color-text-secondary);
  $back_grey: #F3F4F7;

  h3, #text-description {
    color: var(--color-text-primary);
  }

  .stoploss-wrapper {
    margin-top: 100px;
    margin-left: 80px;}
  p {
    margin: 0;}
  hr{
    border-top: 1px rgba(46, 45, 44, 0.08) solid;
    margin: 30px 20px;
    width: 500px;}
  .center-container{
    margin-top: 30px;}
  .stoploss-title {
    color: $color_dark;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;}
  .stoploss-text {
    color: $color_dark;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;}
  .stoploss-ipo {
    margin: 30px 0;
    display: flex;
    align-items: center;}
  .stoploss-result {
    margin: 0 20px;}
  .text-grey {
    color: $color_grey;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;}
  .text-black {
    margin-left: 60px;
    color: $color_black;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.15px;}
  .margin-right {
    margin-right: 12px;}
  .margin-left {
    margin-left: 12px;}
  .margin-bottom {
    margin-bottom: 30px;}
  .input-container-size {
    max-width: 60%;}
  .stoploss-input{
    width: 230px;}

  @media screen and (max-width: 600px) {
    #title-text {
      margin-top: 50px;
      margin-bottom: 12px;
    }
    .stoploss-wrapper {
      margin: 0;
    }
    .main-container{
      width: 90%;
      margin: auto;
    }
    hr{
      width: 250px;
    }
  }

  @media screen and (min-width: 601px) {
    #title-text {
      margin-top: 0px;
      padding-top: 50px;
      margin-bottom: 12px;
    }
    .stoploss-wrapper {
      margin: 0;
    }
    .main-container{
      width: 90%;
      margin: auto;
    }
  }
</style>
