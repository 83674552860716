<template>
  <div class="mdc-switch-wrapper">
    <div class="mdc-switch take-profit-order" :class="component_name">
      <div class="mdc-switch__track"></div>
      <div class="mdc-switch__thumb-underlay">
        <div class="mdc-switch__thumb"></div>
        <input type="checkbox" :id="input_id" class="mdc-switch__native-control" role="switch" aria-checked="true" />
      </div>
    </div>
    <label class="text-grey-uppercase" for="another-basic-switch">{{label_name}}</label>
    {{handler_sibling_toggles}}
  </div>
</template>



<script>
  import { mapGetters } from "vuex";
  import { MDCSwitch } from '@material/switch';

  export default {
    name: 'Toggle',
    props: {
      component_name: {type:String},
      label_name: {type:String},
      input_id: {type:String},
    },
    data() {
      return {
          toggle: {},
      };
    },
    computed: {
      ...mapGetters(['_g_HandlerToggleViews']),

      handler_sibling_toggles() {
            Object.entries(this._g_HandlerToggleViews).forEach((sibling_toggles) => {
                if (this.component_name == sibling_toggles[0]) {
                    this.toggle.checked = sibling_toggles[1];
                }
            });
            return '';
      }
    },
    mounted() {
      this.toggle = new MDCSwitch(document.querySelector('.mdc-switch' + '.' + this.component_name));
      this.toggle.ripple.foundation.adapter.registerInteractionHandler('click', () => {
        this.$emit('_emit_method_check_toggle', [this.toggle.checked, this.component_name]);
      });
    } 
  }
</script>



<style lang="scss">
  @use "@material/switch";
  @use "@material/switch/mdc-switch";
  @use "@material/button";
  
  @include switch.core-styles;

  $color_grey: rgba(0, 0, 0, 0.6);
  $color_dark:  var(--color-text-secondary);

  .mdc-switch {
    @include switch.toggled-on-color(#44BBDD);
    @include switch.toggled-off-color(#797979);
  }
  .mdc-button {
    @include button.shape-radius(50% , 0, 0);
    @include button.horizontal-padding(40px)	
  }	

  @mixin gradient($direction, $list) {
    background: -webkit-linear-gradient($direction, $list);
    background: -moz-linear-gradient($direction, $list);
    background: -o-linear-gradient($direction, $list);
    background: linear-gradient($direction, $list);
  }
  .text-grey-uppercase {
    color: $color_dark;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    margin-bottom: 15px;}
  .relation-orders-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    .mdc-switch-wrapper {
      width: 48%;
      font-size: 14px;
      line-height: 26px;
      .take-profit-order,
      .stop-loss-order,
      .oco-order {
        vertical-align: middle;
      }
      label {
        margin-left: 10px;
        vertical-align: middle;
      }
    }
  }
</style>